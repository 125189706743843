import React from 'react';
import cn from 'classnames';

import styles from './TeacherForm.module.scss';
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
import { dictionaryToSelect, normFile } from '../../utils';
import CustomUpload from '../../components/CustomUpload';
import { useGetLessonSubjectQuery, useGetMetaSubjectsQuery } from '../../store/api/courses';
import { omit } from 'ramda';

const { Title } = Typography;

const TeacherForm = ({
  initialValues = {},
  onSubmit,
  loading,
}) => {

  const {
    data: subjects,
    isLoading: isSubjectsLoading,
    isFetching: isSubjectsFetching,
  } = useGetLessonSubjectQuery();

  const {
    data: metaSubjects,
    isLoading: isMetaSubjectsLoading,
    isFetching: isMetaSubjectsFetching,
  } = useGetMetaSubjectsQuery({ page: -1 });

  const onFinish = async (values) => {
    const preparedValues = omit(
      ['ru', 'en', 'uz'],
      {
        ...values,
        dateOfBirthday: values.dateOfBirthday ? values.dateOfBirthday.format('YYYY-MM-DD') : null,
        translates: [values.ru, values.en, values.uz],
      },
    );

    await onSubmit(preparedValues);
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      className={styles.form}
      disabled={loading}
      initialValues={initialValues}
    >
      <Form.Item
        label="Фото учителя"
        name="picturePath"
        getValueFromEvent={normFile}
        required
      >
        <CustomUpload
          maxCount={1}
          multiple={false}
          group="teacher_photo"
          existedFileUrl={initialValues.pictureUrl}
        />
      </Form.Item>
      <Form.Item
        name="sysFio"
        label="Системное имя (Используется только в админ. панеле)"
        rules={[
          {
            required: true,
            message: 'Системное имя обязательно'
          },
        ]}
        required
      >
        <Input placeholder="Выберите имя по которому сможете быстро определить учителя" />
      </Form.Item>
      <Form.Item
        name="dateOfBirthday"
        label="Дата рождения"
        rules={[
          {
            required: true,
            message: 'Дата рождения обязательна'
          },
        ]}
        required
      >
        <DatePicker
          format="DD.MM.YYYY"
          style={{ width: '100%' }}
          placeholder="Выберите дату рождения"
        />
      </Form.Item>
      <Form.Item
        name="isActive"
        valuePropName="checked"
      >
        <Checkbox>Опубликован?</Checkbox>
      </Form.Item>
      <Form.Item label="Предметы" name="metaSubjects">
        <Select
          showSearch
          placeholder="Выберите предметы"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isMetaSubjectsLoading || isMetaSubjectsFetching}
          options={dictionaryToSelect(metaSubjects?.results)}
          mode="multiple"
        />
      </Form.Item>

      <Row gutter={16}>
        <Col md={24}>
          <Title level={4}>Переводимые поля</Title>
        </Col>
        <Col md={8}>
          {/*   ================     RUSSIAN FIELDS     ================= */}
          <Title level={5}>Русский</Title>
          <Form.Item hidden name={['ru', 'language']} />
          <Form.Item
            name={['ru', 'lastName']}
            label="Фамилия"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Иванов" />
          </Form.Item>
          <Form.Item
            name={['ru', 'firstName']}
            label="Имя"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Иван" />
          </Form.Item>
          <Form.Item
            name={['ru', 'secondName']}
            label="Отчество"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Иванович" />
          </Form.Item>
          <Form.Item
            name={['ru', 'education']}
            label="Образование"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Кандидат наук" />
          </Form.Item>
          <Form.Item
            name={['ru', 'about']}
            label="Об учителе"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Учитель с большой буквы..." />
          </Form.Item>
        </Col>
        <Col md={8}>
          {/*   ================     UZBEK FIELDS     ================= */}
          <Title level={5}>Узбекский</Title>
          <Form.Item hidden name={['uz', 'language']} />
          <Form.Item
            name={['uz', 'lastName']}
            label="Фамилия"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Иванов" />
          </Form.Item>
          <Form.Item
            name={['uz', 'firstName']}
            label="Имя"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Иван" />
          </Form.Item>
          <Form.Item
            name={['uz', 'secondName']}
            label="Отчество"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Иванович" />
          </Form.Item>
          <Form.Item
            name={['uz', 'education']}
            label="Образование"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Кандидат наук" />
          </Form.Item>
          <Form.Item
            name={['uz', 'about']}
            label="Об учителе"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Учитель с большой буквы..." />
          </Form.Item>
        </Col>
        <Col md={8}>
          {/*   ================     ENGLISH FIELDS     ================= */}
          <Title level={5}>Английский</Title>
          <Form.Item hidden name={['en', 'language']} />
          <Form.Item
            name={['en', 'lastName']}
            label="Фамилия"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Иванов" />
          </Form.Item>
          <Form.Item
            name={['en', 'firstName']}
            label="Имя"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Иван" />
          </Form.Item>
          <Form.Item
            name={['en', 'secondName']}
            label="Отчество"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Иванович" />
          </Form.Item>
          <Form.Item
            name={['en', 'education']}
            label="Образование"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Кандидат наук" />
          </Form.Item>
          <Form.Item
            name={['en', 'about']}
            label="Об учителе"
            rules={[
              {
                required: true,
                message: 'Обязательное поле'
              },
            ]}
            required
          >
            <Input placeholder="Учитель с большой буквы..." />
          </Form.Item>
        </Col>
      </Row>
      <Button htmlType="submit" type="primary" block loading={loading}>
        Сохранить
      </Button>
    </Form>
  );
};

export default TeacherForm;
