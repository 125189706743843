import React from 'react';
import cn from 'classnames';

import styles from './TariffList.module.scss';
import { useGetTariffsQuery } from '../../store/api/tariffs';
import { useNavigate } from 'react-router';
import useQueryString from '../../hooks/useQueryString';
import { Skeleton, Table, Tag } from 'antd';
import { formatDate, formatNumber, joinExisting } from '../../utils';
import queryString from 'query-string';
import ROUTES from '../../router/ROUTES';

const TariffList = () => {
  const navigate = useNavigate();
  const { page = 1, pageSize = 20, ...params } = useQueryString();

  const {
    data: tariffs,
    isLoading,
    isFetching,
  } = useGetTariffsQuery({ page, pageSize, ...params });


  const columns = [
    {
      title: 'Название',
      dataIndex: 'sysName',
      key: 'sysName',
    },
    {
      title: 'Месяцев в тарифе',
      dataIndex: 'monthCount',
      key: 'monthCount',
    },
    {
      title: 'Предметов в тарифе',
      dataIndex: 'subjectCount',
      key: 'subjectCount',
      render: (subjectCount, raw) => {
        if (subjectCount > 0) return subjectCount;

        return raw.isUnlimited
          ? <Tag color="green">Неограниченно</Tag>
          : <Tag color="red">?</Tag>
      }
    },
    {
      title: 'Опубликован?',
      dataIndex: 'isArchived',
      key: 'isArchived',
      render: (isArchived, raw) => {
        return isArchived
          ? <Tag color="red">Архивирован</Tag>
          : <Tag color="green">Опубликован</Tag>
      }
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      render: (price) => formatNumber(price),
    },
  ];

  const handlePageChange = (pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...params }),
    })
  };

  if (isLoading) return <Skeleton />;
  if (!tariffs) return null;

  return (
    <>
      <Table
        dataSource={tariffs.results}
        columns={columns}
        rowKey="id"
        loading={isFetching}
        pagination={{
          total: tariffs?.count,
          current: page,
          locale: { items_per_page: '/ на странице' },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
        onRow={(record) => ({
          onClick: () => {
            navigate(ROUTES.tariffDetails(record.id));
          },
        })}
        onChange={handlePageChange}
      />
    </>
  );
};

export default TariffList;
